<template>
    <div id="proile-settings">
        <v-card class="mb-7">
            <v-card-text class="pa-5 border-bottom">
                <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Настройки профиля</h3>
            </v-card-text>
            <v-card-text>
                <center>
                <v-avatar :size="256" v-if="$auth.user().profile.avatar">
      <img
        :src="$auth.user().profile.avatar"
      >
                </v-avatar>
                    <v-avatar  :size="256" v-else>
                        <v-icon :size="256">fa-user-circle</v-icon>
                    </v-avatar><br>
                <v-btn @click="toggleShow()">Изменить фото</v-btn>
                </center>
                <my-upload field="file"
                           @crop-success="cropSuccess"
                           @crop-upload-success="cropUploadSuccess"
                           @crop-upload-fail="cropUploadFail"
                           :url="$http.defaults.baseURL + 'profile/update-avatar'"
                           v-model="showAvatar"
                           langType="ru"
                           :width="300"
                           :height="300"
                           :params="params"
                           :headers="headers"
                           img-format="png"></my-upload>
                <v-text-field
                    :error-messages="errors['name']"
                    v-model="form.name"
                    label="Главное управление МЧС России по субъекту РФ"
                    filled
                    background-color="transparent"
                    ></v-text-field>
                <v-text-field
                    :error-messages="errors['last_name']"
                    v-model="form.last_name"
                    label="Структурное подразделение (Номер ПСЧ/СПСЧ)"
                    filled
                    background-color="transparent"
                    ></v-text-field>


                <v-text-field
                    v-model="form.second_name"
                    label="Занимаемая должность"
                    filled
                    background-color="transparent"
                    ></v-text-field>
                <!--<v-text-field
                    v-model="form.birthday"
                    :error-messages="errors['birthday']"
                    label="Дата рождения"
                    class="mt-3"
                    filled
                    background-color="transparent"
                    type="date"
                    ></v-text-field>-->
                <v-text-field
                    :error-messages="errors['organization']"
                    v-model="form.organization"
                    label="Номер караула"
                    filled
                    background-color="transparent"
                    ></v-text-field>
                <v-text-field
                    :error-messages="errors['position']"
                    v-model="form.position"
                    label="Номер по книге службы"
                    filled
                    background-color="transparent"
                    ></v-text-field>
                <v-btn color="success" @click="onSubmit()" class="text-capitalize mr-2 mb-4">Сохранить</v-btn>
                <v-alert             
                    :value="alert"
                    color="success"
                    type="success"

                    dense
                    dark
                    transition="scale-transition"
                    >
                    Сохранено!
                </v-alert>
            </v-card-text>

        </v-card>




    </div>
</template>
<script>
    
    import myUpload from "vue-image-crop-upload/upload-2.vue";
    export default {
        name: 'profile-settings',
        components: {
            myUpload
        },
        data() {
            return {
                security: false,
                form: {},
                errors: [],
                show: true,
                showAvatar: false,
                alert: false,
                params: {
                    token: '123456798',
                    name: 'avatar',
                    langType: 'en'
                },
                headers: {
                    Authorization: 'Bearer '+this.$auth.token()
                }
            }
        },
        methods: {
            setSecurity() {
                this.security = true
            },
            onSubmit() {
                this.$http.put('profile/update?id=' + this.$auth.user().id, {
                    Profile: this.form
                })
                        .then(response => {
                            // JSON responses are automatically parsed.
                            // this.errors = response.data.errors
                            if (response.data.errors) {
                                this.errors = []
                                for (var prop in response.data.errors) {
                                    var key = prop.replace('profile-', '')
                                    this.errors[key] = response.data.errors[prop][0]
                                }
                            } else
                            {
                                this.$auth.user().profile.name = this.form.name
                                this.$auth.user().profile.second_name = this.form.second_name
                                this.$auth.user().profile.last_name = this.form.last_name
                                this.errors = []
                                this.alert = true
                                setTimeout(
                                        () => {
                                    this.alert = false
                                },
                                        1 * 1000
                                        );
                            }
                            console.log(this.errors)
                        })
            },
            fetchData() {
                this.$http.get('profile/get', {
                    params: {
                        id: this.$auth.user().id
                    }
                }).then(response => {
                    // JSON responses are automatically parsed.
                    this.form = response.data
                })
                        .catch(e => {
                            this.errors.push(e)
                        })
            },
            onInput( { number, isValid, country }) {
                if (!isValid) {
                    this.errors.phone = 'Номер телефона введен неверно'
                } else {
                    this.errors.phone = ''
                }
                console.log(number, isValid, country)
            },
            toggleShow() {
                this.showAvatar = !this.showAvatar
            },
            cropSuccess() {
                console.log('-------- crop success --------')
            },
            /**
             * upload success
             *
             * [param] jsonData  server api return data, already json encode
             * [param] field
             */
            cropUploadSuccess(jsonData, field) {
                this.$auth.user().profile.avatar = jsonData
                console.log('-------- upload success --------')
                console.log(jsonData)
                console.log('field: ' + field)
            },
            /**
             * upload fail
             *
             * [param] status    server api return error status, like 500
             * [param] field
             */
            cropUploadFail(status, field) {
                console.log('-------- upload fail --------')
                console.log(status)
                console.log('field: ' + field)
            }
        },

        created() {
            this.fetchData()
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
